const GLIMR_TAG_STORAGE_KEY = 'glimrTags';

export type GlimrTags = string[];
export type GlimrTagMappings = Record<string, string[]>;

export interface GlimrLocalStorageItem {
  tags: GlimrTags;
  tagMappings: GlimrTagMappings;
}

const MAX_CACHE_AGE = 24 * 60 * 60 * 1000;

function getGlimrLocalStorageItem(): GlimrLocalStorageItem | null {
  // both frimand and inventory write to the same cache key
  const item = window.localStorage.getItem(GLIMR_TAG_STORAGE_KEY);

  if (!item) {
    return null;
  }

  try {
    const { timestamp, expirationTime, data } = JSON.parse(item);

    // inventory sets `expirationTime` (Date.now() + 24h)
    // ref https://github.schibsted.io/inventory/ads-inv-lib-advertory/blob/ef3157057a4cf6d90e8df2ab790ef23b35a555a2/src/utils/local-storage.ts#L6
    if (expirationTime && expirationTime > Date.now()) {
      return data;
    }

    // frimand sets timestamp (Date.now())
    // ref https://github.schibsted.io/vg/frimand/blob/22d598303fd706fb72f88aed3f85f265ebca15eb/resources/js/glimr/helpers.js#L4
    if (timestamp && timestamp + MAX_CACHE_AGE > Date.now()) {
      return data;
    }
  } catch {
    // noop
  }

  return null;
}

export function getGlimrTags(): GlimrTags | undefined {
  const glimrData = getGlimrLocalStorageItem();
  return glimrData?.tags;
}
